import React, { useEffect, useState } from 'react'
import axios from 'axios';
import BlogComponent from '../../components/BlogComponent/BlogComponent';
import './blog.css';
import Skeleton from 'react-loading-skeleton'



export default function Blog() {

  const [data, setData] = useState([])

  useEffect(() => {
    const fetch = () => {
      axios.get('https://ezeguy.onrender.com/api/blogs/allblogs')
      .then(res => setData(res?.data?.data))
    }

    fetch();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])




  return (
    <div className='blog-container'>
      { 
        !data ? 
        <div className='skeleton'>
        </div>
        :
        data.reduce((acc, curr)=>{
          acc.unshift(curr)
          return acc
        }, [])?.map((blog, _) => {
          return (
            <div className='single-blog' key={_}>
              <BlogComponent data={blog} />
            </div>
          )
        }) 
        
      }
    </div>
  )
}
