import React, { useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar.js'
import Footer from './components/Footer/Footer.js';

import Home from './pages/Home/Home.js';
import Blog from './pages/Blog/Blog.js';
import Contact from './pages/Contact/Contact.js';
import Services from './pages/Services/Services.js';
import About from './pages/About/About.js';
import SingleBlog from './pages/SingleBlog/SingleBlog.js';
import Login from './pages/Login/Login.js';
import PrivateRoutes from './components/PrivateRoutes.js';
import AllBlogs from './pages/AllBlogs/AllBlogs.js';
import CreateBlog from './pages/CreateBlog/CreateBlog.js';

export default function App() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div>
      <Navbar />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog/:id" element={<SingleBlog />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/all-blogs" element={<AllBlogs/>} />
          <Route path="/create-blog" element={<CreateBlog/>} />
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>

      <Footer />
    </div>
  )
}
