import React from 'react'
import Button from '../../components/Button/Button';
import './hero.css';


export default function Hero ({ background, url, title }) {
  return (
    <div className='hero'>
      <img src={background} alt="hero_image" className='hero-image'/>
      <div className='overlay'> </div>
      <div className='content'>
        <h1> {title} </h1>
        {url && <Button url={url} />}
      </div>
    </div>
  )
}
