import React, { useEffect } from 'react';
import './services.css';
import Hero from '../../components/Hero/Hero';

import heroBackground from '../../assets/services/hero.jpg';
import { services_data } from '../../utils';
import Button from '../../components/Button/Button';



export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='services'>
      <Hero background={heroBackground} url={'/contact'} title="Services" />

      <main>
        <h2 className='title'> L&N Services </h2>
        <p> Welcome to L & N Travels & Tours Limited, where your travel dreams take flight under the visionary leadership of our esteemed CEO, Prophetess Mrs. Lydia E. Mark King. Nestled in the vibrant heart of Lagos, Nigeria, L & N Travels & Tours LTD stands as a beacon of excellence, offering a comprehensive array of travel and logistics services. </p>


        {
          services_data?.map((service, _) => {
            return (
              <aside>
                <div>
                  <img src={service.img} alt=''/>
                </div>
                <div>
                  <p> {service.msg} </p>
                </div>
              </aside>
            )
          })
        }

        <br />
        <Button text="Book Now!" url="/contact" />
      </main>
    </div>
  )
}
