
import React, { useState } from 'react'
import { useful_links } from '../../utils';
import { Link, useLocation } from 'react-router-dom';
import './mobilenavbar.css';
import { RiCloseCircleFill } from "react-icons/ri";
import { PiListDashesFill } from "react-icons/pi";



export default function MobileNavbar() {
  const location = useLocation();
  const pathname = location.pathname;
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='mobile-navbar'>
      <span className='open' onClick={() => setIsOpen(!isOpen)}> <PiListDashesFill /> </span>

        <div className={`drawer ${isOpen ? 'open-drawer' : 'close-drawer'}`}>
          <span className='close' onClick={() => setIsOpen(!isOpen)}> <RiCloseCircleFill /> </span>
          <ol>
            {
              useful_links.map((_, idx) => {
                return (
                  <span key={idx}>
                    <Link to={_?.url}>
                      <li style={{ color: pathname === _?.url ? 'orange' : 'black'}} onClick={() => setIsOpen(!isOpen)}>
                        {_?.title}
                      </li>
                    </Link>
                  </span>
                )
              })
            }
          </ol>
        </div>
    
    </div>
  )
}
