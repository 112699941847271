import React from 'react';
import './footer.css';
import { useful_links } from '../../utils';

import { FaFacebook, FaInstagram } from "react-icons/fa6";
import { FaPhoneFlip } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";

import { Link, useLocation } from 'react-router-dom';
import { FaPlusCircle } from "react-icons/fa";


export default function Footer() {
  const location = useLocation();
  const pathname = location.pathname;


  return (
    <div className='footer'>
      <section className='first-section'>
        <div>
          <h2> L&N Travels </h2>
          <p> At L & N Travels and Logistics, we redefine the art of travel, delivering bespoke experiences tailored to the discerning L & N Travels and Logistics. </p>

          <p className='title'> Follow Us </p>
          <aside className='follow-us'>
            <p> <a href="https://www.facebook.com/lnntravels?mibextid=2JQ9oc" rel="noreferrer" target='_blank'> <FaFacebook /> </a> </p>
            <p> <a href="https://www.instagram.com/lnn_travels?igsh=ZmJmMzZ5OW5qaTFu" rel="noreferrer" target='_blank'> <FaInstagram /> </a> </p>
          </aside>
          <br />
          { pathname === '/blog' && 
            <div className='open-login'> 
              <Link to="/all-blogs">
                <FaPlusCircle /> 
              </Link>
            </div>
          }
        </div>

        <div className='useful-links'>
          <p className='title'> Useful Links </p>
          <ul>
            {
              useful_links.map((link, _) => {
                return (
                  <li key={_}>
                    <Link to={link.url}>
                      {link.title}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </div>

        <div className='contact'>
          <p className='title'> Contact Us </p>
          <p> <IoLocationSharp /> 5 Aromolaran Close, Ojodu Estate, Ojodu Berger, Ikeja, Lagos </p>
          <p> <FaPhoneFlip /> +2349064498091, 08129005608, 08109476406 </p>
          <p> <IoMdMail /> lnn.travelsntours@gmail.com </p>
        </div>
      </section>

      <section className='second-section'>
        <p> Copyright &copy; {new Date().getFullYear()} </p>
        <p> 
          <a href="https://www.instagram.com/oma_media_space__" rel='noreferrer' target='_blank'> 
            Developed by oma media
          </a>
        </p>
      </section>
    </div>
  )
}
