
import logistics_tall_icon from './assets/home/logisitics_tall.jpg';
import travel_tall_icon from './assets/home/travel_tall.jpg';
import travel_icon_1 from './assets/home/travel1.jpg';
import travel_icon_2 from './assets/home/travel2.jpg';

import package1 from './assets/home/packages/package-1.jpg';
import package2 from './assets/home/packages/package-2.jpg';
import package3 from './assets/home/packages/package-3.jpg';
import package4 from './assets/home/packages/package-4.jpg';
import package5 from './assets/home/packages/package-5.jpg';
import package6 from './assets/home/packages/package-6.jpg';

import guide1 from './assets/home/guides/guide1.jpeg'
import guide3 from './assets/home/guides/guide3.jpeg'
import guide4 from './assets/home/guides/guide4.jpeg'
import guide5 from './assets/home/guides/guide5.jpeg'

import testimonial1 from './assets/home/testimonials/testimonial-1.jpg'
import testimonial2 from './assets/home/testimonials/testimonial-2.jpg'
import testimonial3 from './assets/home/testimonials/testimonial-3.jpg'

import service1 from './assets/services/1.jpg'
import service2 from './assets/services/2.jpg'
import service3 from './assets/services/3.jpg'
import service4 from './assets/services/4.jpg'
import service5 from './assets/services/5.jpg'
import service6 from './assets/services/6.jpg'
import service7 from './assets/services/7.jpg'

import bikes from './assets/bikes.jpeg';



export const core_features_data = [
  {
    tag: 'logistics',
    title: 'We Provide Best Logistics and Quick Delivery',
    msg: 'Beyond travel, we excel in logistics services that connect businesses and individuals alike. From local dispatch services to international cargo shipments, our logistics solutions are designed to meet the demands of a dynamic world.',
    img: logistics_tall_icon,
    img1: bikes,
    img2: bikes,
  },
  {
    tag: 'travels',
    title: 'We Provide Comfortable and Quality Travels',
    msg: "Discover a world of possibilities with our comprehensive travel services. Whether you're planning a business trip, a family vacation, or a solo adventure, we tailor our offerings to meet your unique needs. From flight bookings to accommodation arrangements, we handle every detail with precision and care.",
    img: travel_tall_icon,
    img1: travel_icon_1,
    img2: travel_icon_2,
  },
];



export const why_chose_us = [
  {
    title: 'Experience',
    msg: 'With years of industry expertise, we bring a seasoned approach to every service we offer. Our team is dedicated to providing a seamless and stress-free experience for all our clients.'
  },
  {
    title: 'Customer-Centric Approach',
    msg: 'At the core of our philosophy is a commitment to our clients. We prioritize your needs and preferences, ensuring that every interaction with us exceeds expectations.'
  },
  {
    title: 'Global Network',
    msg: 'Our extensive network allows us to offer a range of travel and logistics solutions on a global scale. Wherever your journey takes you, trust L & N Travels & Tours LTD to be your reliable partner.'
  },
];


export const packages_data = [
  package1, package2, package3, package4, package5, package6
];


export const guides_data = [
  {
    title: 'CEO',
    name: 'LYDIA MARK KING',
    img: guide1,
  },
  {
    title: 'TRAVEL AGENT',
    name: 'BENJAMIN EMEKA',
    img: guide3,
  },
  {
    title: 'MARKETER',
    name: 'IFEAYINWA',
    img: guide4,
  },
  {
    title: 'STAFFS',
    name: 'OTHER',
    img: guide5,
  },
]


export const testimonials_data = [
  {
    title: 'Designer',
    name: 'Kennedy J.',
    img: testimonial1,
    msg: 'L & N Travels & Tours handled my hotel bookings with utmost efficiency. The accommodations exceeded my expectations, and their commitment to ensuring a comfortable stay was evident. Grateful for their excellent service!'
  },
  {
    title: 'Painter',
    name: 'Michael Y.',
    img: testimonial2,
    msg: "Outstanding travel advisory! L & N Travels & Tours provided invaluable insights that enhanced my travel experience. Their team's knowledge and dedication exceeded my expectations. Thank you for making my journey unforgettable!"
  },
  {
    title: 'Seamstress',
    name: 'Michelle A.',
    img: testimonial3,
    msg: 'Exceptional service! L & N Travels & Tours made my international trip a breeze. From securing my visa to booking flights and suggesting fantastic accommodations, their attention to detail is unmatched. Highly recommended!'
  },
]


export const useful_links = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Services',
    url: '/services',
  },
  {
    title: 'About',
    url: '/about',
  },
  {
    title: 'Blog',
    url: '/blog',
  },
  {
    title: 'Contact',
    url: '/contact',
  },
]


export const services_data = [
  {
    img: service1,
    msg: 'At L & N Travels & Tours, we redefine logistics with a commitment to excellence. From local dispatch to international cargo shipments, trust us to navigate the complexities and ensure your goods reach their destination seamlessly. Your success, our priority. 🌐🚚 #LogisticExcellence #LNLogistics'
  },
  {
    img: service2,
    msg: 'City to city, doorstep to doorstep - our local dispatch services connect you effortlessly. L & N Logistics ensures swift and reliable deliveries within Lagos and beyond. Your packages, our punctuality. 🏙️📦 #LocalDispatch #LNLogistics'
  },
  {
    img: service3,
    msg: 'Global Shipping Solutions. Thinking global? So are we! L & N Logistics extends its reach worldwide, offering tailored shipping solutions that transcend borders. Your cargo, our expertise. Ship confidently with us. 🌎🚢 #GlobalShipping #LNTravels'
  },
  {
    img: service4,
    msg: 'At L & N, our logistics services are built around you. Experience a customer-centric approach that prioritizes your needs, offering solutions that go beyond expectations. Your requirements, our commitment. 🌐🛍️ #CustomerFirst #LNTravels'
  },
  {
    img: service5,
    msg: 'When time is of the essence, choose L & N Logistics for express courier services. Swift, secure, and tailored to meet your urgent delivery needs. Your deadlines, our commitment. ⏰📬 #ExpressCourier #LNTravels'
  },
  {
    img: service6,
    msg: 'Building trust through every shipment. L & N Logistics forms lasting partnerships by delivering reliability, integrity, and excellence in every logistics solution. Your trust, our foundation. 🤝📦 #TrustedLogistics #LNLogistics'
  },
  {
    img: service7,
    msg: 'Building trust through every shipment. L & N Logistics forms lasting partnerships by delivering reliability, integrity, and excellence in every logistics solution. Your trust, our foundation. 🤝📦 #TrustedLogistics #LNLogistics'
  },
]


export const about_data = [
  {
    title: 'About us',
    intro: 'Welcome to L & N Travels & Tours Limited, where your travel dreams take flight under the visionary leadership of our esteemed CEO, Prophetess Mrs. Lydia E. Mark King. Nestled in the vibrant heart of Lagos, Nigeria, L & N Travels & Tours LTD stands as a beacon of excellence, offering a comprehensive array of travel and logistics services.',
    content: [
      {
        tag: 'About our CEO',
        msg: 'Prophetess Mrs. Lydia E. Mark King, the driving force behind our company, brings a wealth of experience and a deep commitment to service. Her visionary leadership sets the tone for our dedicated team, ensuring that every journey is not just a trip but an extraordinary experience.'
      },
      {
        tag: 'Our Company',
        msg: 'L & N Travels & Tours LTD is a proudly Nigerian company dedicated to providing unparalleled travel and logistics services. Situated in Lagos, the commercial hub of Nigeria, we leverage our strategic location to offer seamless solutions for both domestic and international travel.'
      },
    ]

  },
  {
    title: 'our services',
    content: [
      {
        tag: 'Travel Services',
        msg: "Discover a world of possibilities with our comprehensive travel services. Whether you're planning a business trip, a family vacation, or a solo adventure, we tailor our offerings to meet your unique needs. From flight bookings to accommodation arrangements, we handle every detail with precision and care."
      },
      {
        tag: 'Visa Processing',
        msg: 'Navigating the complexities of visa applications is made easy with our expert guidance. Trust us to facilitate smooth visa processing for destinations including the US, Canada, the UK, and beyond. Your global journey starts with L & N Travels & Tours LTD.'
      },
      {
        tag: 'Logistics Services',
        msg: 'Beyond travel, we excel in logistics services that connect businesses and individuals alike. From local dispatch services to international cargo shipments, our logistics solutions are designed to meet the demands of a dynamic world.'
      },
    ]

  },
  {
    title: 'WHY CHOOSE L & N TRAVELS & TOURS LTD?',
    content: [
      {
        tag: 'Experience',
        msg: "With years of industry expertise, we bring a seasoned approach to every service we offer. Our team is dedicated to providing a seamless and stress-free experience for all our clients."
      },
      {
        tag: 'Customer-centric approach',
        msg: 'At the core of our philosophy is a commitment to our clients. We prioritize your needs and preferences, ensuring that every interaction with us exceeds expectations.'
      },
      {
        tag: 'Global network',
        msg: "Our extensive network allows us to offer a range of travel and logistics solutions on a global scale. Wherever your journey takes you, trust L & N Travels & Tours LTD to be your reliable partner.As you explore the world with us, let L & N Travels & Tours LTD be your compass, guiding you through the exciting realms of travel and logistics. Welcome aboard, and let's make your journey extraordinary!"
      },
    ]

  }
]