import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './single-blog.css';
import Skeleton from 'react-loading-skeleton';
import ReactMarkdown from 'react-markdown'


export default function SingleBlog() {
  const { id } = useParams();
  const [data, setData] = useState([])

  useEffect(() => {
    const fetch = () => {
      axios.get(`https://ezeguy.onrender.com/api/blogs/${id}/singleblog`)
      .then(res => setData(res?.data?.data))
    }

    fetch();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

   
  return (
    <div className='dynamic-blog'> 
      {
        data?.length < 1 ? <Skeleton count={5} />  :
        <main>
          <img src={data?.image} alt='' className='banner'/>

          <div className='info'>
            <p className='title'> {data?.title} </p>
            <p className='author'> By {data?.author} </p>
            <p className='description'> {data?.description} </p>
            <br />
            <p> <ReactMarkdown>{data?.content?.replace(/\. /g, '.\n\n')}</ReactMarkdown> </p>
          </div>
        </main>

      }
    </div>
  )
}
