import React, { useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';

import heroBackground from '../../assets/services/hero.jpg';
import Hero from '../../components/Hero/Hero';



export default function Contact() {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [loading, set_loading] = React.useState(false);

  const [data, set_data] = React.useState({
    from_name: "",
    to_name: "L&N Travels and Tours",
    message: "Hi, We want to make deliveries on XYZ. Are you chanced so we can schedule a meeting?",
    reply_to: "",
  });

  function handle_data (e) {
    set_data(prev => ({
      ...prev,
      [e.target.name] : e.target.value,
    }))
  }

  async function handle_submit (e) {
    e.preventDefault();

    if(data.from_name.trim().length < 1 || data.reply_to.trim().length < 1 || data.message.trim().length < 1) {
      alert('Please, fill all fields')
      return false;
    }

    set_loading(true)
    await emailjs.send("service_oxlkd31", "template_gegbtfi", data, 'qccXDUuqK6I3EPm5X')
      .then((result) => {
          alert('Thanks!, we got your message!😁')
          set_loading(false);

          set_data ({
            from_name: "",
            to_name: "L&N Travels and Tours",
            message: "Hi, We want to make deliveries on XYZ. Are you chanced so we can schedule a meeting?",
            reply_to: "",
          })

      }, (error) => {
          set_loading(false);
      });

  }


  return (
    <div className='contact'>
      <Hero background={heroBackground} title="Contact" />

      <p className='tag'> CONTACT </p>
      <h2 className='title'> SEND US A MESSAGE! 😊 </h2>

      <form action="#" method="GET">
        <aside>
          <input 
            type='text' 
            name='from_name'
            value = {data.from_name}
            className='input'
            onChange = {handle_data}
            placeholder='Full name'
            required
          />

          <input 
            type='email' 
            name='reply_to'
            value = {data.reply_to}
            placeholder='Email'
            className='input'
            onChange = {handle_data}
            required
            />

        </aside>
        <textarea
          label="Your Message"
          multiline
          rows={8}
          name="message"
          value = {data.message}
          fullWidth
          onChange = {handle_data}
          required
          className='text-area'
        />

        <button className='btn' onClick={handle_submit}> 
          {loading ? 
            'loading' : 
            "Shoot!" 
          } </button> 
      </form>

      
    </div>
  )
}
