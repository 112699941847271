import React from 'react';
import './button.css';
import { Link } from 'react-router-dom';

export default function Button ({ text, url }) {
  return (
    <span className='button-container'>
      <Link to={url}>
        <div className='button'> 
          {text ? text : 'Get Started!'} 
        </div>
      </Link>
    </span>
  )
}
