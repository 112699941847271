import React, { useEffect } from 'react';
import './about.css';
import Hero from '../../components/Hero/Hero';

import heroBackground from '../../assets/services/hero.jpg';
import { about_data } from '../../utils';
import Button from '../../components/Button/Button';

import img1 from '../../assets/home/travel1.jpg'
import img2 from '../../assets/home/travel2.jpg'



export default function About () {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='about'>
      <Hero background={heroBackground} url={'/contact'} title="About" />

      <main>
        <h2 className='title'> L&N Services </h2>
        <p className='intro'> Welcome to L & N Travels & Tours Limited, where your travel dreams take flight under the visionary leadership of our esteemed CEO, Prophetess Mrs. Lydia E. Mark King. Nestled in the vibrant heart of Lagos, Nigeria, L & N Travels & Tours LTD stands as a beacon of excellence, offering a comprehensive array of travel and logistics services. </p>


        {
          about_data?.map((about, _) => {
            const { intro, title, content } = about;
            return (
              <section key={_}>
                <h3 className='big-tag'> {title} </h3>
                <p> {intro && intro} </p>
                {
                  content.map((each, idx) => {
                    const {tag, msg} = each;
                    return (
                      <aside key={idx}>
                        <h3 className='small-tag'> {tag} </h3>
                        <p> {msg} </p>
                      </aside>
                    )
                  })
                }
              </section>
            )
          })
        }


        <section className='twin-images'>
          <img src={img1} alt='' />
          <img src={img2} alt='' />
        </section>


        <br />
        <Button text="Book Now!" url="/contact" />
      </main>
    </div>
  )
}
