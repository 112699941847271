import React from 'react';
import './blog_component.css';
import { Link } from 'react-router-dom';

export default function BlogComponent({ data }) {
  const {author, content, description, title, image, _id} = data;

  return (
    <div className='blog-component'>
      <Link to={`/blog/${_id}`} target='blank'>
        <img src={image} alt='' />
        <aside>
          <p className='title'> {title} </p>
          <p className='description'> {description} </p>
        </aside>
      </Link>
    </div>
  )
}
