import axios from 'axios';
import React, { useEffect, useState } from 'react'
import './allblogs.css';
import Skeleton from 'react-loading-skeleton';
import { RiCloseCircleFill } from "react-icons/ri";
import { Link } from 'react-router-dom';



export default function AllBlogs() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)


  useEffect(() => {
  const fetch = () => {
    axios.get('https://ezeguy.onrender.com/api/blogs/allblogs')
    .then(res => setData(res?.data?.data))
  }

    fetch();
  }, []);

  const handleDelete = (id) => {
    // eslint-disable-next-line no-restricted-globals
    let confirmation = confirm('Are you sure you want to delete?');
    if(confirmation) {
      setLoading(true)
      axios.delete(`https://ezeguy.onrender.com/api/blogs/${id}/mutateBlog`)
      .then(res => {
        setLoading(false);
        window.location.reload();
      })
      .catch(err => {
        setLoading(false);
        alert('Sorry, something went wrong, try again!')
      })
    }
  }




  const ListBlog = ({ data, id }) => {
    return (
      <div className='list-blog'> 
        <div>
          <aside className='id'> {id + 1} </aside>
          <p> {data.title} </p>
        </div>
        <div onClick={() => handleDelete(data?._id)}>
          <RiCloseCircleFill />
        </div>
      </div>
    )
  }



  return (
    <div className='all-blogs-container'>

      {
        loading &&
        <div className='delete-loader'>
          <div></div>
        </div>
      }



      <h2 className='title'>
        <Link to="/create-blog">
          New Post + 
        </Link> 
      </h2>

      { 
        data?.length > 0 ?
        data.reduce((acc, curr)=>{
          acc.unshift(curr)
          return acc
        }, []).map((blog, _) => {
          return (
            <div className='single-list' key={_}>
              <ListBlog data={blog} id={_} />
            </div>
          )
        }) :
        <Skeleton count={5} /> 
      }

    </div>
  )
}
